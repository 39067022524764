<template>
  <popup-window v-slot="scope" ref="popupWindow" :can-close="canClose">
    <span
      v-if="canClose"
      style="text-align: right; margin-top: -1rem; width: 100%"
    >
      <button
        style="cursor: pointer; margin-top: -2rem; padding: 1rem"
        @click="scope.close"
      >
        X
      </button>
    </span>
    <h2 class="title title-l">Helaas! Je hebt geen CJP-lidmaatschap!</h2>
    <p>
      Deze deal is alleen bedoeld voor CJP-leden. Klik op de button hieronder om
      een lidmaatschap te krijgen
    </p>
    <div class="buttons">
      <a
        class="button--primary"
        target="_blank"
        href="https://shop.cjp.nl/product/jaarlidmaatschap/"
        >Word nu lid</a
      >
    </div>
  </popup-window>
</template>

<script>
import EventBus from "./../event-bus";

export default {
  name: "PopupActiveMembershipRequired",
  props: {
    open: {
      type: Boolean,
      default: false,
      required: false,
    },
    canClose: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      target: "_blank",
      url: "https://shop.cjp.nl/product/jaarlidmaatschap/",
    };
  },
  mounted() {
    EventBus.$on("openPopupActiveMembershipRequired", () => {
      this.$refs.popupWindow.openPopUp();
    });
    if (this.open) {
      this.$refs.popupWindow.openPopUp();
    }
  },
  methods: {},
};
</script>
