<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="voucher-code"
    @click="copy()"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="voucher-code__code" :class="{ hover: hover || copied }">
      {{ displayCode }}
      <span v-if="copied" class="hover-text">{{ "Copied" | t }}</span>
      <span v-else-if="hover" class="hover-text">{{
        "Click to copy" | t
      }}</span>
      <i class="icon fal fa-copy"></i>
    </div>
    <p class="voucher-code__disclaimer">
      {{ "You can also find the code in your email" | t }}
    </p>
    <div
      v-if="confirmationText"
      class="voucher-code__confirmation-text"
      v-html="confirmationText"
    ></div>
  </div>
</template>

<script>
import { LOCAL_SESSION_ORDER } from "../constants/localStorage";

export default {
  name: "VoucherCode",
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
      copied: false,
      displayCode: null,
    };
  },
  computed: {
    confirmationText() {
      const orderInfo = JSON.parse(sessionStorage.getItem(LOCAL_SESSION_ORDER));
      return orderInfo ? orderInfo.confirmation_text : null;
    },
  },
  mounted() {
    this.displayCode = this.code;
  },
  methods: {
    copy() {
      this.copyAction();
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1000);
    },
    copyAction() {
      const el = document.createElement("textarea");
      el.value = this.code;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
  },
};
</script>
